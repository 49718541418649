import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'

import DefaultLayout from '@hoc/Layout/Default'

import Link from '@components/Link'
import Button from '@components/Button'

import { getLinkFromModel } from '@utils'

const Error = ({ data }) => {
    const { theme } = data
    const _bg = convertToBgImage(getImage(theme?.errorBackground))

    // console.log(theme?.errorCharacter)

    return (
        <DefaultLayout {...{ theme }}>
            <div className="flex flex-col items-center justify-center w-full min-h-[50vh] px-6 md:px-8 py-32 md:py-48 relative z-20 overflow-hidden text-center">
                <BackgroundImage
                    {...{
                        ..._bg,
                        className: 'w-full h-full absolute top-0 left-0 z-0',
                        style: { position: 'absolute' },
                    }}
                />

                <GatsbyImage
                    image={getImage(theme?.errorCharacter)}
                    className="hidden md:block w-96 h-auto absolute bottom-0 left-2/3 transform md:-translate-x-1/2"
                    alt="Error"
                />

                <h1 className="inline-block pr-12 mb-4 relative font-display font-semibold text-5xl md:text-6xl lg:text-7xl text-white uppercase tracking-wider">
                    {theme?.errorHeading}
                    <span className="heading-triangle block w-6 h-5 absolute top-0 right-0 transform origin-top-left bg-white" />
                </h1>

                <span className="max-w-lg relative font-medium text-4xl text-white uppercase">
                    {theme?.errorBody}
                </span>

                {theme?.errorButtons?.length ? (
                    <div className="flex flex-col xl:flex-row items-center justify-center mt-8 md:mt-12">
                        {theme?.errorButtons?.map((button, _index) => (
                            <Link
                                key={_index}
                                {...{
                                    ...button?.link?.[0],
                                    to: getLinkFromModel(button?.link?.[0]),
                                }}
                            >
                                <Button
                                    {...{
                                        ...button,
                                        className: `w-full md:w-auto ${_index === 0
                                            ? 'ml-0'
                                            : 'my-4 xl:mt-0 xl:ml-4'
                                            }`,
                                    }}
                                >
                                    {button?.link?.[0]?.label}
                                </Button>
                            </Link>
                        ))}
                    </div>
                ) : null}
            </div>
        </DefaultLayout>
    )
}

export const query = graphql`
    query getError {
        theme: datoCmsTheme {
            headerLinks {
                label
                linkType
                page {
                    ... on DatoCmsPage {
                        slug
                        model {
                            name
                        }
                    }
                    ... on DatoCmsPost {
                        slug
                        model {
                            name
                        }
                    }
                }
                url
                element
            }

            footerLinks {
                label
                linkType
                page {
                    ... on DatoCmsPage {
                        slug
                        model {
                            name
                        }
                    }
                    ... on DatoCmsPost {
                        slug
                        model {
                            name
                        }
                    }
                }
                url
                element
            }

            logosInline {
                url
            }
            logosInlineWhite {
                url
            }

            socialsFacebook
            socialsYoutube
            socialsTwitter
            socialsDiscord
            socialsLinkedin
            socialsInstagram

            errorHeading
            errorBody
            errorBackground {
                gatsbyImageData(placeholder: BLURRED)
            }
            errorCharacter {
                gatsbyImageData(placeholder: BLURRED)
            }
            errorButtons {
                color
                icon
                link {
                    linkType
                    label
                    page {
                        ... on DatoCmsPage {
                            title
                            slug
                            model {
                                name
                            }
                        }
                        ... on DatoCmsPost {
                            title
                            slug
                            model {
                                name
                            }
                        }
                    }
                    url
                    element
                }
                image {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
        }
    }
`

export default Error
